import React, { useState } from 'react';

export default function DragAndDrop({ onDrop }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length) {
      setIsLoaded(true);
      onDrop(files[0]);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoaded(true);
      onDrop(file);
    }
  };

  return (
    <div className='flex flex-col gap-[10px]'>
      <div className='draganddrop' onDragOver={handleDragOver} onDrop={handleDrop}>
        Drag and drop a file here or click to upload
        <input type="file" className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer' onChange={handleFileInputChange} />
      </div>
      {isLoaded && <p className='text-[#2ecc71] font-bold'>File loaded successfully!</p>}
    </div>
  );
}