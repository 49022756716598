import React from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

export default function BottomMenu() {
  return (
    <div className='flex flex-col py-[10px] rounded-tr-[5px] w-full'>
      <div className='flex items-center font-medium !text-white cursor-pointer p-[10px] rounded-[5px] transition-all duration-300 mb-[10px] hover:bg-[#34495e] border border-[#ffffff9b]'>
        <RocketLaunchIcon className='mr-[10px]' />
        Upgrade <span className='bg-[#086310] py-[3px] px-[10px] ml-[6px] rounded-[3px] text-[14px]'>Pro</span>
      </div>
      <div className='flex items-center text-white mb-[10px] cursor-pointer px-[10px] py-[10px] rounded-[5px] transition-all duration-300 hover:bg-[#34495e]'>
        <AccountBoxIcon className='mr-[10px]' />
        Account
      </div>
      <div className='flex items-center text-white mb-[10px] cursor-pointer py-[10px] px-[10px] rounded-[5px] transition-all duration-300 hover:bg-[#34495e]'>
        <SettingsIcon className='mr-[10px]' />
        Settings
      </div>
    </div>
  );
}