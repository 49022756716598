import React from 'react';

function getBarColor(progress) {
  if (progress < 50) {
    return 'lightblue';
  } else if (progress < 100) {
    return 'orange';
  } else {
    return 'red';
  }
}


export default function CreditsModule({ value, maxValue }) {
  const progress = (value / maxValue) * 100;
  const barStyle = {
    width: `${progress}%`,
    backgroundColor: getBarColor(progress),
  };

  return (
    <div className='w-full mb-[5px]'>
      <div className='flex justify-between w-full text-white text-[14px] mb-[5px]'>
        <div >Usage</div>
        <div >{`${value}/${maxValue}`}</div>
      </div>
      <div className='w-full relative rounded-[5px] overflow-hidden h-[5px] shadow-[0 2px 4px rgba(0,0,0,0.1) transition-all duration-300]'>
        <div className='h-full absolute top-0 left-0 bg-[#ffffff4d] w-full'></div>
        <div className='h-full absolute top-0 left-0' style={barStyle}></div>
      </div>
    </div>
  );
}