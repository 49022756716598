import React, { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Modal from '@mui/material/Modal';
import DragAndDrop from './DragAndDrop';

export default function UploadFileButton() {
  const [open, setOpen] = useState(false);

  const handleFileUpload = (file) => {
    console.log('New file made:', file);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
  const body = (
    <div className='relative w-[320px] sm:w-96  bg-white p-[20px] rounded-[5px] flex flex-col gap-4'>
      <h2>Upload File</h2>
      <DragAndDrop onDrop={handleFileUpload} />
      <button className='bg-[#3498db] text-white text-[14px] font-bold p-[5px] border-none cursor-pointer transition-all duration-300' onClick={handleClose}>
        Close
      </button>
    </div>
  );

  return (
    <>
      <button className='uplodebtn' onClick={handleOpen}>
        <CloudUploadIcon className='mr-[10px]' />
        Upload File
      </button>
      <Modal open={open} onClose={handleClose} className=' flex justify-center items-center ' >
        {body}
      </Modal>
    </>
  );
}