import React from 'react';
import Auth from './pages/Auth';
import './index.css';
import Dashboard from './pages/Dashboard';

function App() {
  let user = true;
  return (
    <div className="mx-auto p-0 w-full">
      {user ? <Dashboard /> : <Auth />}
    </div>
  );
}

export default App;