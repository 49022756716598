import React from 'react';
import FileChat from './FileChat/FileChat';
import Logo from './Logo/Logo';
import BottomMenu from './BottomMenu/BottomMenu';
import Credits from './CreditsModule/Credits';
import UploadFileButton from './UploadFIle/UploadFileButton';
export default function LeftPanel() {
  return (
    <div className='flex flex-col h-full px-2'>
      <div className='flex flex-col justify-between items-center flex-none border-b border-b-[#ffffff1d] mb-[10px]'>
        <Logo />
      </div>
      <div className='flex flex-col justify-between items-center flex-1 '>
        <UploadFileButton />
        <FileChat />
      </div>
      <div className='flex flex-col justify-between items-center'>
        <Credits value={4000} maxValue={5000} />
        <BottomMenu />
      </div>
    </div>
  );
}