import React, { useState } from 'react';
// import DescriptionIcon from '@mui/icons-material/Description';
// import CsvFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BarChartIcon from '@mui/icons-material/BarChart';

const files = [
  {
    icon: <InsertDriveFileIcon />,
    title: 'MLB Stats 2017',
    subtitle: 'Text file'
  },
  {
    icon: <BarChartIcon />,

    title: 'April-June finance report',
    subtitle: 'CSV file'
  },
  {
    icon: <PictureAsPdfIcon />,

    title: 'File3.pdf',
    subtitle: 'PDF file'
  }
];

function File({ file, isSelected, onClick }) {
  return (
    <div
      className={` filechat ${isSelected ? '!bg-[#172233]' : ''}`}
      onClick={onClick}
    >
      <div className='w-10 h-10 bg-[#31425d] flex justify-center items-center text-[20px] mr-[10px] rounded-[5px]'>
        {React.cloneElement(file.icon, {
          style: { fontSize: '30px', color: isSelected ? '#3498db' : 'whitesmoke' }
        })}
      </div>
      <div className='flex flex-col'>
        <div className='mb-[5px] text-[14px] text-slate-100'>{file.title}</div>
        <div className='text-[12px] text-gray-400'>{file.subtitle}</div>
      </div>
    </div>
  );
}

export default function FileChat() {
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);

  return (
    <div className='w-full h-full overflow-y-hidden py-3 mx-2 mb-4'>
      {files.map((file, index) => (
        <File
          key={index}
          file={file}
          isSelected={index === selectedFileIndex}
          onClick={() => setSelectedFileIndex(index)}
        />
      ))}
    </div>
  );
}