import React, { useState  } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import LeftPanel from '../../components/LeftPanel/LeftPanel';

function ToggleButtonLeft({ onClick}) {
  return (
    <button className='absolute top-[10px] left-[10px] bg-[#ffffff1a] border-0 text-yellow-50 text-[24px] py-[2px] px-[10px] rounded-[3px] cursor-pointer' onClick={onClick}>
      ☰
    </button>
  );
}
function ToggleButtonRight({ onClick}) {
  return (
    <button className='absolute top-[10px] right-[10px] bg-[#ffffff1a] border-0 text-yellow-50 text-[24px] py-[2px] px-[10px] rounded-[3px] cursor-pointer' onClick={onClick}>
      ☰
    </button>
  );
}

export default function Dashboard() {


  const isMobile = useIsMobile();

  const [isLeftPanelVisible, setIsLeftPanelVisible] = useState(!isMobile);
  const [isRightPanelVisible, setIsRightPanelVisible] = useState(!isMobile);

  const toggleLeftPanel = () => {
    setIsLeftPanelVisible(!isLeftPanelVisible);
  };

  const toggleRightPanel = () => {
    setIsRightPanelVisible(!isRightPanelVisible);
  };

  return (
    <div className='flex h-screen bg-[#1f2b3e] m-0' >
      <div className= {`py-[10px] flex overflow-hidden duration-500 transition-all w-[238px] bg-[#1f2b3e] ${isLeftPanelVisible ? '' : 'flex-none !w-0 p-0 opacity-0 -translate-x-full' }`}>
        <LeftPanel />
      </div>
      <div className='py-[10px] flex overflow-hidden duration-500 transition-all relative flex-1  bg-[#101722]'>
        <ToggleButtonRight onClick={toggleRightPanel} />
        <ToggleButtonLeft onClick={toggleLeftPanel} />
      </div>
      <div className={`py-[10px] !flex overflow-hidden duration-500 transition-all w-[223px] bg-[#1f2b3e]  ${isRightPanelVisible ? '' : 'flex-none !w-0 p-0 opacity-0 -translate-x-full'} `}>
        <div className='text-white px-2'>
          col 3 content

        </div>
      </div>
    </div>
  );
}